import React, { useRef } from "react";

const Default = ({ data, cab, hijos, campokey, indiceData, id_elemento }) => {
  const condcionBadge = cab.id_a === "T_COL_ID_TRANSFER" && "0.9em";
  const ref = useRef(null);
  if (!data[campokey] && data[campokey] != 0) return <></>;
  return (
    <div
      id={"DEFAULT_COLUMNA_SIMPLE_BASE_" + cab.id_a + "_" + indiceData}
      style={{
        display: "flex",
        justifyContent: cab.align ?? "left",
      }}
    >
      <div
        id={id_elemento}
        className={data[cab.id_a + "_className"]}
        style={{
          textAlign: cab.align ?? "left",
          fontSize: condcionBadge,
          border: cab.border,
          borderTop: cab.borderTop,
        }}
        ref={ref}
      >
        {data[campokey]}
        {hijos}
      </div>
    </div>
  );
};

export default Default;

export const Code = ({
  data,
  cab,
  hijos,
  campokey,
  indiceData,
  id_elemento,
}) => {
  return (
    <code
      id={id_elemento}
      className={data[cab.id_a + "_className"]}
      style={{ textAlign: cab.align ?? "left", color: "red" }}
    >
      {data[campokey]}
      {hijos}
    </code>
  );
};
