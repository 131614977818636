import React, { useState, useContext, useEffect } from "react";
import FuncionesContext from "../../../context/FuncionesContext";
import PantallaContext from "../../../context/PantallaContext";
import Label from "./LabelF";
import { Switch } from "@material-ui/core";
import { esMinuscula } from "../../../helper/funciones";

const Toggle = ({
  setValor,
  valor,
  cab,
  error,
  setError,
  data,
  id_elemento,
}) => {
  const { superSubmit } = useContext(FuncionesContext);

  const { configuraciones_ref } = useContext(PantallaContext);

  const [value, setValue] = useState(valor);
  const [lastValue, setLastvalue] = useState(valor);

  let si = "s";
  let no = "n";

  if (valor) {
    si = esMinuscula(valor) ? "s" : "S";
    no = esMinuscula(valor) ? "n" : "N";
  }

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const handleCancelar = () => {
    setValue(valor);
    setLastvalue(valor);
  };

  const handleChange = async (e) => {
    const valor = e.target.checked ? si : no;

    if (valor === null || lastValue?.toString() === valor.toString().trim())
      return;

    setValor(valor);
  };

  useEffect(() => {
    if (configuraciones_ref[cab.id_a] === 1) return;
  }, [configuraciones_ref[cab.id_a]]);

  return (
    <>
      <div
        id={id_elemento}
        className="tarjeta_grid_item_label_item"
        style={{
          justifyContent: "end",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {cab.nombre ? (
          <Label
            label={data[cab.id_a + "_nombre"] ?? cab.nombre}
            opcionales_null={cab.opcionales_null}
            permite_null={cab.permite_null}
          />
        ) : (
          <></>
        )}
        <Switch
          color="primary"
          checked={valor === null ? false : valor === si ? true : false}
          onChange={handleChange}
          label={data[cab.id_a + "_label"] ?? cab.label}
          style={{ alignSelf: "flex-end" }}
        />
      </div>
    </>
  );
};

export default Toggle;
