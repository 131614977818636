import React, { useState, useContext } from "react";
import Select from "../../../components/Select";
import FuncionesContext from "../../../context/FuncionesContext";
import ListadoContext from "../../context/ListadoContext";
const S = require("sweetalert2");

const SelectC = ({ data, cab, hijos, campokey, indiceData, id_elemento }) => {
  const funciones = useContext(FuncionesContext);
  const { superSubmit } = funciones;

  const { ListadoDispatch, Dispatch } = useContext(ListadoContext);

  const [value, setValue] = useState(data[campokey]);
  const [lastValue, setLastvalue] = useState(data[campokey]);

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const handleCancelar = () => {
    setValue(data[campokey]);
    setLastvalue(data[campokey]);
  };

  const handleChange = async (e) => {
    const valor = funciones.transformarTipoDato(e.target.value, typeof value);

    const update_id = data[cab.update_id_alias];

    if (valor === null || lastValue?.toString() === valor.toString().trim())
      return;

    const { id_a } = cab;

    setValue(valor);

    await superSubmit({ valor, id_a, update_id, handleCancelar, cab, data })
      .then((result) => {
        setLastvalue(valor);
        ListadoDispatch({
          type: "SET_DATO_ESPECIFICO",
          payload: {
            key: cab.update_id_alias, // update_id
            indiceData: indiceData, // 4
            value: result.data.registro.id,
          },
        });

        ListadoDispatch({
          type: "SET_DATO_ESPECIFICO",
          payload: {
            key: cab.id_a, // update_id
            indiceData: indiceData, // 4
            value: valor,
          },
        });

        if (cab.efecto_funcion && cab.efecto_funcion.trim() !== "") {
          if (
            (cab.efecto_valor && cab.efecto_valor == valor) ||
            !cab.efecto_valor
          ) {
            const funcion =
              data[cab.id_a + "_efecto_funcion"] ?? cab.efecto_funcion;
            const datosActuales = { ...data, [cab.id_a]: valor };

            funciones[funcion]({
              data: datosActuales,
              cab,
              indiceData,
              handleCancelar,
              dispatch: Dispatch,
            });
          }
        }
      })
      .catch((err) => {
        console.log("Cancelado ", err);
        handleCancelar();
      });
  };

  const disabled = (() => {
    if (data[cab.id_a + "_disabled"]) {
      return data[cab.id_a + "_disabled"] === "s";
    }
    if (cab.disabled) {
      return cab.disabled === "s";
    }
    return false;
  })();

  return (
    <div id={id_elemento} style={{ textAlign: "center" }}>
      <Select
        nombre={nombre}
        opciones={cab.opciones ? cab.opciones : []}
        value={value}
        onChange={handleChange}
        def={cab.default}
        disabled={disabled}
        className={data[cab.id_a + "_className"] ?? cab.className}
      />
      {hijos}
    </div>
  );
};

export default SelectC;
