import React, { useState, useContext, useEffect } from "react";
import FuncionesContext from "../context/FuncionesContext";
import TextArea from "./TextArea";
import { TextField } from "@material-ui/core";

const InputAreaEditable = ({
  data,
  cab,
  hijos,
  campokey,
  indiceData,
  type,
  context,
  id_elemento,
}) => {
  const { superSubmit } = useContext(FuncionesContext);

  const { datos, Dispatch } = useContext(context);

  const [value, setValue] = useState(data[campokey]);
  const [lastValue, setLastvalue] = useState(data[campokey]);

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const disabled = data[cab.id_a + "_disabled"]
    ? data[cab.id_a + "_disabled"] === "s"
    : cab.disabled === "s";

  let classNames = data[cab.id_a + "_className"] ?? cab.className;

  const handleCancelar = () => {
    setValue(data[campokey]);
    setLastvalue(data[campokey]);
  };

  const handleGuardar = async (e) => {
    const valor = e.target.value;

    if (valor === null || lastValue?.toString() === valor.toString().trim())
      return;

    const update_id = data[cab.update_id_alias];
    const { id_a } = cab;

    superSubmit({ valor, id_a, update_id, handleCancelar, cab, data })
      .then((result) => {
        setLastvalue(() => valor);

        Dispatch({
          type: "SET_DATO_ESPECIFICO",
          payload: {
            value: result.data.registro.id,
            indiceData,
            key: cab.update_id_alias,
          },
        });

        Dispatch({
          type: "SET_DATO_ESPECIFICO",
          payload: {
            value: valor,
            indiceData,
            key: cab.campo_alias ? cab.campo_alias : id_a,
          },
        });

        return result;
      })
      .catch((err) => {
        console.log("Cancelado ", err);
      });
  };

  (() => {
    if (data[cab.update_id_alias]) {
      classNames = `${classNames} 
        ${data[cab.id_a + "_update_className"] ?? cab.update_className}`;
      return;
    }
    classNames = classNames + " sinUpdateId";
    return;
  })();

  return (
    <div id={id_elemento} className="tarjeta_grid_item_label_item">
      {nombre ? <div className="vista_label">{nombre}:</div> : <></>}
      {type === "number" ? (
        <TextField
          id={cab.id_a}
          type="number"
          onBlur={handleGuardar}
          defaultValue={value}
          inputProps={{
            style: {
              textAlign: cab.align ?? "right",
              display: "flex",
              alignItems: "baseline",
              minWidth: "max-content",
            },
          }}
          disabled={disabled}
          className={disabled ? classNames + " textField_disabled" : classNames}
        />
      ) : (
        <TextArea
          disabled={disabled}
          className={disabled ? classNames + " textArea_disabled" : classNames}
          value={value}
          setValue={setValue}
          onEnter={handleGuardar}
          // style={style}
          id={id_elemento}
        />
      )}
    </div>
  );
};

export default InputAreaEditable;
