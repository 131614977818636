import React, { useEffect, useState, useContext, useCallback } from "react";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import Label from "./LabelF";
import Axios from "axios";
import { farmageo_api } from "../../../../../config";
import FuncionesContext from "../../../context/FuncionesContext";
import PantallaContext from "../../../context/PantallaContext";

export const SelectF = (props) => {
  const { refrescarConfiguracion } = useContext(FuncionesContext);
  const { configuraciones_ref } = useContext(PantallaContext);
  const {
    id_a,
    filtrosAAplicar,
    setFiltrosAAplicar,
    opciones: op,
    select_es_maestro,
    select_depende_de,
    select_todas,
  } = props;

  const [opciones, setOpciones] = useState(() => {
    if (select_todas) {
      return op;
    }
    return [{ value: " ", label: "Todas", default: true }].concat(op);
  });

  const f = filtrosAAplicar[select_depende_de];

  useEffect(() => {
    // setOpciones([{ value: " ", label: "Todas", default: true }].concat(op));
    if (configuraciones_ref[id_a] === 1) return;

    Axios.post(farmageo_api + "/select/opciones", {
      [id_a]: filtrosAAplicar[select_depende_de],
    }).then((res) => {
      setOpciones(res.data);
    });
  }, [configuraciones_ref[id_a]]);

  const handleChange = (valor) => {
    setFiltrosAAplicar((prevState) => {
      return { ...prevState, [id_a]: valor };
    });
    if (select_es_maestro === "s") {
      refrescarConfiguracion({ cab: props });
      return;
    }
  };

  useEffect(() => {
    if (!f) return;
    if (select_depende_de) {
      setFiltrosAAplicar((prevState) => {
        return { ...prevState, [id_a]: " " };
      });
      setOpciones(() => {
        if (select_todas) {
          return [];
        }
        return [{ value: " ", label: "Todas", default: true }];
      });
    }
  }, [f, select_depende_de]);

  return (
    <div className="filtroSelect">
      <FormControl fullWidth>
        <Label
          label={props.label}
          opcionales_null={props.opcionales_null}
          permite_null={props.permite_null}
        />
        <Select
          style={{ width: "95%", height: "32px", fontSize: "0.8rem" }}
          defaultValue={props.default}
          variant="standard"
          onChange={(e) => handleChange(e.target.value)}
          value={
            isNaN(Number(filtrosAAplicar[id_a])) ||
            filtrosAAplicar[id_a] === " "
              ? " "
              : Number(filtrosAAplicar[id_a])
          }
          error={props.error[id_a]}
        >
          {opciones &&
            opciones.map((opcion) => {
              return (
                <MenuItem
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "0.8rem",
                  }}
                  value={opcion.value}
                  key={opcion.value + opcion.label}
                >
                  {opcion.default ? <em>{opcion.label}</em> : opcion.label}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectF;
