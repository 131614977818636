import React, { useState, useContext, useEffect } from "react";
import Select from "../../../components/Select";
import ListadoContext from "../../context/ListadoContext";
import FuncionesContext from "../../../context/FuncionesContext";
const S = require("sweetalert2");

const SelectOnSubmit = ({
  data,
  cab,
  hijos,
  campokey,
  indiceData,
  id_elemento,
}) => {
  const { addSideData, sideData } = useContext(ListadoContext);
  const { refrescarConfiguracion } = useContext(FuncionesContext);
  const { id_a } = cab;

  const [value, setValue] = useState(data[campokey]);
  const [lastValue, setLastvalue] = useState(data[campokey]);

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const handleCancelar = () => {
    setValue(data[campokey]);
    setLastvalue(data[campokey]);
  };

  const disabled = (() => {
    if (data[cab.id_a + "_disabled"]) {
      return data[cab.id_a + "_disabled"] === "s";
    }
    if (cab.disabled) {
      return cab.disabled === "s";
    }
    return false;
  })();

  const handleChange = async (e) => {
    let valor = e.target.value;

    if (valor === null || lastValue?.toString() === valor.toString().trim())
      return;

    if (typeof cab.opciones[0].value === "number") {
      valor = parseInt(valor, 10);
    }

    addSideData({ id_a, valor });

    setValue(valor);

    if (cab.select_es_maestro === "s") {
      refrescarConfiguracion({ cab });
      return;
    }
  };

  useEffect(() => {
   
    if (cab.default && cab.default !== sideData[cab?.id_a]) {
      let def = cab.default;
      if (typeof cab.opciones[0].value === "number") {
        def = parseInt(cab.default, 10);
      }
      addSideData({ id_a: cab.id_a, valor: def });
    }
  },[addSideData, cab.default, cab.id_a, cab.opciones]);

  return (
    //<div style={{ textAlign: "center"}}>
    <div id={id_elemento} style={{ flex: "1 0 100%" }}>
      <div
        className="vista_label_select"
        //style={{ fontWeight: "bold", display: "flex", width: "100%" }}
      >
        {cab.nombre && cab.nombre !== "" ? (
          <p style={{ flexShrink: 0, flexWrap: "wrap" }}>{cab.nombre}:</p>
        ) : (
          <></>
        )}
        <Select
          nombre={nombre}
          opciones={cab.opciones ? cab.opciones : []}
          value={value}
          onChange={handleChange}
          def={cab.default}
          disabled={disabled}
        />
        {hijos}
      </div>
    </div>
  );
};

export default SelectOnSubmit;
