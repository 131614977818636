import React, { useContext, StrictMode } from "react";
import SubPantalla from "../SubPantalla/SubPantalla";
import { ListadoProvider } from "../Listado/Listado";
import ConfigListado from "../Listado/components/ConfigListado";
import Vista from "../Vista/Vista";
import ABMProvider from "../ABM/ABMProvider";
import ABM from "../ABM/ABM";
import Modal from "./Modal";
import RedirectP from "./RedirectP";
import ModalesContext from "../context/ModalContext";
import PantallaContext from "../context/PantallaContext";
import { Mensaje } from "./Mensaje";

//cabeceras data opciones
const SwitchMaestro = ({
  configuracion,
  id,
  _key,
  nollamar,
  idx,
  params,
  props,
  qsBody,
}) => {
  const modalContext = useContext(ModalesContext);
  const { getModal } = modalContext;

  const pantallaContext = useContext(PantallaContext);
  const { PantallaDispatch } = pantallaContext;

  if (props) {
    configuracion.opciones.esModal = props.esModal;
  }

  const calcComponente = ({
    configuracion,
    id,
    _key,
    nollamar,
    idx,
    params,
    props,
    qsBody,
  }) => {
    switch (configuracion.opciones.tipo.id) {
      //Listado
      case 2:
        return (
          <ListadoProvider
            key={_key}
            configuracion={configuracion}
            id={id}
            nollamar={nollamar}
            idx={idx}
            params={params}
            modalProps={props}
            qsBody={qsBody}
          >
            <ConfigListado />
          </ListadoProvider>
        );
      //Vista
      case 6:
        return (
          <Vista
            // key={_key}
            configuracion={configuracion}
            id={id}
            nollamar={nollamar}
            idx={idx}
            params={params}
            modalProps={props}
            qsBody={qsBody}
          />
        );
      case 7:
        return (
          <SubPantalla
            key={_key}
            configuracion={configuracion}
            id={id}
            nollamar={nollamar}
            idx={idx}
            params={params}
            modalProps={props}
            qsBody={qsBody}
          />
        );

      case 1:
        return (
          <SubPantalla
            key={_key}
            configuracion={configuracion}
            id={id}
            nollamar={nollamar}
            idx={idx}
            params={params}
            modalProps={props}
            qsBody={qsBody}
          />
        );

      case 9:
        return (
          <ABMProvider
            key={_key}
            configuracion={configuracion}
            id={id}
            nollamar={nollamar}
            idx={idx}
            params={params}
            modalProps={props}
            qsBody={qsBody}
          >
            <ABM />
          </ABMProvider>
        );
      case 11:
        return <RedirectP configuracion={configuracion} modalProps={props} />;
      case 14:
        return (
          <Mensaje
            qsBody={qsBody}
            params={params}
            modalProps={props}
            configuracion={configuracion}
          />
        );
      default:
        return <></>;
    }
  };

  if (
    configuracion.opciones.modal ||
    getModal(configuracion.opciones.id_a).id_a
  ) {
    const { getModal, cerrarModal, zIndex } = modalContext;
    const modal = getModal(configuracion.opciones.id_a);
    modal.esModal = true;
    // const cfg = configuracion;
    // cfg.opciones.modal = false;
    const ComponenteParaModal = calcComponente({
      configuracion,
      id: modal.parametro_id,
      _key: "modal" + configuracion.opciones.id_a,
      nollamar,
      params: modal.data,
      props: modal,
      qsBody: modal.qsBody,
    });

    return (
      <Modal
        open={modal.open}
        modalContainerStyle={{
          // top: "150px",
          // left: "250px",
          // width: "max-content",
          // maxWidth: "calc(100% - 400px)",
          // margin: "0 calc((100% - 400px)/20)",
          // padding: "5px",
          // background: "white",
          // borderRadius: "15px",
          // transform: "translate(0%, 0%)",
          zIndex: modal.zIndex + 1,
        }}
        className="modalContainerStyle"
        zIndex={modal.zIndex}
        handleClose={() => {
          // PantallaDispatch({
          //   type: "KILL_CONFIGURACION",
          //   payload: configuracion.opciones.id_a,
          // });
          cerrarModal(configuracion.opciones.id_a);
        }}
        data={configuracion}
      >
        {ComponenteParaModal}
      </Modal>
    );
  }
  const Componente = calcComponente({
    configuracion,
    id,
    _key,
    nollamar,
    idx,
    params,
    props,
    qsBody,
  });
  return <StrictMode>{Componente}</StrictMode>;
};

export default SwitchMaestro;
