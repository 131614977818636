import React, { useState, useContext, useEffect } from "react";
import TextArea from "../../../components/TextArea";
import ListadoContext from "../../context/ListadoContext";
import { TextField } from "@material-ui/core";

const InputOnSubmit = ({
  data,
  cab,
  hijos,
  campokey,
  indiceData,
  type = cab.type,
  id_elemento,
}) => {
  const { ListadoDispatch } = useContext(ListadoContext);

  const [value, setValue] = useState(data[campokey]);
  const [lastValue, setLastvalue] = useState(data[campokey]);

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const handleCancelar = () => {
    setValue(data[campokey]);
    setLastvalue(data[campokey]);
  };

  const handleChange = async (e) => {
    const valor = e.target.value;

    if (valor === null || lastValue?.toString() === valor.toString().trim())
      return;

    const { id_a } = cab;

    ListadoDispatch({
      type: "SET_DATO_ESPECIFICO",
      payload: {
        value: valor,
        indiceData,
        key: cab.campo_alias ? cab.campo_alias : id_a,
      },
    });

    // setLastvalue(() => value);
    setValue(() => valor);
  };

  const style = (() => {
    if (data[cab.update_id_alias]) {
      return {
        textAlign: cab.align ?? "left",
        borderColor: "darkgreen",
        borderWidth: "3px",
        fontSize: "0.8em",
      };
    }
    return {
      textAlign: cab.align ?? "left",
      borderColor: "grey",
      fontSize: "0.8em",
    };
  })();

  let Componente = (
    <TextArea
      value={value ?? ""}
      setValue={setValue}
      onEnter={handleChange}
      style={style}
      id={id_elemento}
    />
  );

  if (type === "number") {
    return (Componente = (
      <TextField
        id={id_elemento}
        type="number"
        onChange={handleChange}
        value={value}
        defaultValue={value}
        inputProps={{
          style: { textAlign: "right", fontSize: "0.8em", padding: 0 },
        }}
        variant="standard"
      />
    ));
  }

  if (type === "textarea") {
    return (Componente = (
      <TextArea
        value={value ?? ""}
        setValue={setValue}
        onEnter={handleChange}
        style={style}
        id={id_elemento}
      />
    ));
  }

  if (!type || type === "text") {
    return (Componente = (
      <TextField
        id={id_elemento}
        type="text"
        onChange={handleChange}
        defaultValue={value}
        inputProps={{
          style: { textAlign: "center", fontSize: "0.8em", padding: 0 },
        }}
        variant="outlined"
      />
    ));
  }

  return <div className="tarjeta_grid_item_label_item">{Componente}</div>;
};

export default InputOnSubmit;
