import React, { useContext } from "react";
import VistaContext from "../context/VistaContext";
import SwitchCampos from "./SwitchCampos";
import PantallaContext from "../../context/PantallaContext";
import { Card, CardBody } from "reactstrap";
import { agregarAcciones } from "../../helper/funciones";

import "./Tarjeta.scss";
import "./Vista.scss";
import HeaderConf from "../../components/HeaderConf";

const Tarjeta = () => {
  const { datos, cabeceras, opciones, id, qsBody } = useContext(VistaContext);
  const { opciones_de_pantalla } = useContext(PantallaContext);

  const styles = {
    gridColumn: opciones.grid_span ? opciones.grid_span : "1 / -1",
    border: "none",
    marginBottom: 0,
  };

  const gridTemplatecolumns = () => {
    if (datos.length === 1) return "repeat(12, 1fr)";

    return "repeat(auto-fill, minmax(340px, 1fr)";
  };

  const gridcolumns = () => {
    if (datos.length === 1) return "span 12";
    return undefined;
  };

  agregarAcciones(opciones_de_pantalla, opciones);

  return (
    <Card style={styles} id={opciones.id_a} className="animated fadeIn">
      <HeaderConf
        opciones={opciones}
        className="configuracion_pantalla_titulo_secundario"
      />
      <CardBody style={{ padding: 0 }}>
        {datos.length === 0 ? (
          <div>{opciones.vista_sql_vacio}</div>
        ) : (
          <div
            className="tarjeta"
            style={{
              gridTemplateColumns: gridTemplatecolumns(),
            }}
          >
            {/* {datos.length === 0
            ? `(No se recuperaron Datos del id especificado) id: ${id}`
            : null} */}

            {datos.length === 0 ? (
              <div
                style={{
                  gridColumn: "1/-1",
                }}
                className={`tarjeta_grid_item ${opciones.className}`}
              >
                {cabeceras.map((cab, i) => {
                  return (
                    <SwitchCampos
                      data={{ nada: "nada" }}
                      cab={cab}
                      key={cab.id_a + i}
                      indiceData={i}
                      Context={VistaContext}
                      id_elemento={cab.id_a + i}
                    />
                  );
                })}
              </div>
            ) : (
              datos.map((dato, indiceData) => {
                return (
                  <div
                    style={{
                      gridColumn: gridcolumns(),
                    }}
                    key={JSON.stringify(dato)}
                    className={`tarjeta_grid_item ${opciones.className}`}
                  >
                    {cabeceras
                      .sort((a, b) => a.orden - b.orden)
                      .map((cab, i) => (
                        <SwitchCampos
                          key={cab.id_a}
                          indiceData={indiceData}
                          data={dato}
                          cab={cab}
                          Context={VistaContext}
                          id_elemento={cab.id_a + indiceData}
                          id_global={id}
                          idx={i}
                          qsBody={qsBody}
                        />
                      ))}
                  </div>
                );
              })
            )}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default Tarjeta;
