import React, { useState, useEffect, useReducer, useRef } from "react";
import axios from "axios";

import { farmageo_api } from "../../config";

import PantallaContext from "./context/PantallaContext";
import { requestErrorHandler } from "./context/FuncionesContext";
import { FuncionesProvider } from "./context/FuncionesContext";
import PantallaReducer, { initialState } from "./context/PantallaReducer";
import { useParams, useLocation } from "react-router";
import SwitchMaestro from "./components/SwitchMaestro";
import { AlertasProvider } from "./context/AlertaContext";
import HeaderConf from "./components/HeaderConf";
import { agregarAcciones } from "./helper/funciones";
import { ModalProvider } from "./context/ModalContext";

import Debugger from "./components/Debugger";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const CancelToken = axios.CancelToken;

const Pantalla = () => {
  const [state, dispatch] = useReducer(PantallaReducer, initialState);

  let { pantalla } = useParams();
  let { search, state: locationState = { filtros: {} } } = useLocation();

  const [loadingPantalla, setLoadingPantalla] = useState(true);

  const params = new URLSearchParams(search);
  let id = params.get("id");

  let qsBody = {};

  const qs = search?.split("?")[1];
  const ref = localStorage.getItem("referrer");
  const ses = sessionStorage.getItem("referrer");
  const referrer = ref
    ? JSON.parse(localStorage.getItem("referrer"))
    : undefined;
  const session = ses
    ? JSON.parse(sessionStorage.getItem("referrer"))
    : undefined;

  if (qs) {
    const qsPairs = qs.split("&");
    qsBody = (() => {
      let resBody = {};

      qsPairs.forEach((q) => {
        const kp = q.split("=");
        if (!kp[0]) return;
        resBody[kp[0]] = kp[1];
      });
      return resBody;
    })();
  }
  if (referrer) {
    localStorage.removeItem("referrer");
    sessionStorage.setItem("referrer", JSON.stringify(referrer));

    if (referrer.filtros) {
      locationState.filtros = referrer.filtros;
    }
  }
  if (session?.filtros) {
    locationState.filtros = session.filtros;
  }

  const cancelSource = useRef(null);

  useEffect(() => {
    setLoadingPantalla(true);

    cancelSource.current = CancelToken.source();
    axios
      .post(farmageo_api + "/pantalla/" + pantalla, qsBody, {
        params: locationState?.filtros,
        cancelToken: cancelSource.current.token,
      })
      .then((response) => {
        if (response.status >= 400) {
          requestErrorHandler(response);

          return response;
          if (!response.data.error?.continuar) return response;
        }

        dispatch({
          type: "SET_CONFIGURACIONES_REF",
          payload: response.data,
        });
        dispatch({
          type: "SET_CONFIGURACIONES",
          payload: response.data.configuraciones,
        });

        const opciones_de_pantalla = { ...response.data.opciones };
        response.data.configuraciones[0].opciones.orden = 1;
        agregarAcciones(
          opciones_de_pantalla,
          response.data.configuraciones[0]?.opciones
        );

        dispatch({
          type: "SET_OPCIONES_DE_PANTALLA",
          payload: opciones_de_pantalla,
        });
        dispatch({
          type: "SET_PANTALLA",
          payload: pantalla,
        });

        dispatch({
          type: "SET_PANTALLA_ID",
          payload: id,
        });

        setLoadingPantalla(false);
        return response;
      })
      .catch((err) => {
        console.log("cancel request...", err);
      })
      .then((response) => {
        dispatch({
          type: "ADD_SQL",
          payload: response.data.sql,
        });
      })
      .catch((error) => {
        console.log(error);
        return;
      });
    return () => {
      cancelSource.current.cancel();
    };
  }, [pantalla, id]);

  const ref_ida = state.configuraciones_ref[pantalla];

  useEffect(() => {

    if (ref_ida > 1) {
      setLoadingPantalla(true);
      axios
        .post(farmageo_api + "/pantalla/" + pantalla, qsBody, {
          params: locationState?.filtros,
          cancelToken: cancelSource.current.token,
        })
        .then((response) => {
          if (response.status >= 400) {
            requestErrorHandler(response);

            return response;
          }

          dispatch({
            type: "SET_CONFIGURACIONES",
            payload: response.data.configuraciones,
          });

          response.data.configuraciones[0].opciones.orden = 1;

          setLoadingPantalla(false);
          return response;
        })
        .catch((err) => {
          console.log("cancel request...", err);
        })
        .then((response) => {
          dispatch({
            type: "ADD_SQL",
            payload: response.data.sql,
          });
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    }
  }, [ref_ida]);

  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = state.opciones_de_pantalla.title ?? defaultTitle.current;
  }, [state.opciones_de_pantalla]);

  return (
    <PantallaContext.Provider
      value={{
        configuraciones: state.configuraciones,
        opciones_de_pantalla: state.opciones_de_pantalla,
        pantalla: state.pantalla,
        pantalla_id: state.pantalla_id,
        configuraciones_ref: state.configuraciones_ref,
        configuraciones_ids: state.configuraciones_ids,
        configuraciones_cab: state.configuraciones_cab,
        filtrosAplicados: state.filtrosAplicados,
        PantallaDispatch: dispatch,
        loadingPantalla,
        sql: state.sql,
        qsBody,
      }}
    >
      <AlertasProvider>
        <ModalProvider>
          <FuncionesProvider>
            <Debugger />

            <HeaderConf
              opciones={state.opciones_de_pantalla}
              className="configuracion_pantalla_titulo_principal"
            />
            <div
              id={pantalla}
              style={{
                paddingBottom: "1.5rem",
                overflow: "hidden",
                minHeight: "90vh",
              }}
            >
              {loadingPantalla ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginTop: "20px",
                    flexDirection: "column",
                    fontSize: "16px",
                    color: "#006f7a",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    spin
                    style={{ fontSize: "50px" }}
                  />
                  <div style={{ marginTop: "10px" }}>Cargando...</div>
                </div>
              ) : (
                state.configuraciones
                  .sort((a, b) => a.opciones.orden - b.opciones.orden)
                  .map((item, idx) => {
                    return (
                      <SwitchMaestro
                        key={item.opciones.id_a}
                        _key={item.id_a}
                        configuracion={item}
                        id={id}
                        idx={idx}
                        qsBody={qsBody}
                      />
                    );
                  })
              )}
            </div>
          </FuncionesProvider>
        </ModalProvider>
      </AlertasProvider>
    </PantallaContext.Provider>
  );
};

export default Pantalla;
