import React from "react";
import { lightFormat, parseJSON } from "date-fns";

const FechaC = ({ data, cab, hijos, campokey, indiceData, id_elemento }) => {
  try {
    const className = data[cab.id_a + "_className"] ?? cab.className;

    return (
      <div
        id={id_elemento}
        className={className}
        style={{ textAlign: "center" }}
      >
        {data[campokey]
          ? lightFormat(
              parseJSON(data[campokey]),
              cab["fecha_formato"] ? cab["fecha_formato"] : "yyyy-MM-dd HH:mm"
            )
          : null}
        {hijos}
      </div>
    );
  } catch (err) {
    console.log(data[campokey], err);
    return <>{data[campokey]}</>;
  }
};

export default FechaC;
