import React from "react";
import { Button } from "@material-ui/core";
import "../../botonera.scss";
import { fontTheme } from "../../../helper/themeMui";

const Buscar = ({ handleSubmit, loading }) => {
  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={handleSubmit}
        disabled={loading}
        style={{
          height: "fit-content",
          marginRight: "20px",
          background: "#006f7a",
          color: "white",
          fontFamily: fontTheme.typography.fontFamily
        }}
      >
        Buscar
      </Button>
    </>
  );
};

export default Buscar;
