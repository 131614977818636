import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "../components/Pantalla.scss";

export default function Modal({
  open,
  handleClose,
  children,
  modalContainerStyle, // = {},
  zIndex,
  data, //= { opciones: { id_a: "" } },
  className,
}) {
  const d = open ? "block" : "none";
  modalContainerStyle.display = d;
  modalContainerStyle.minWidth = "60vw";

  const modalRef = useRef(null);

  useEffect(() => {
    const modalContainer = modalRef.current;
    const windowHeight = window.innerHeight;

    function adjustModalPosition() {
      const modalRect = modalContainer.getBoundingClientRect();
      const modalTop = modalRect.top;

      if (modalTop < 0) {
        const offset = Math.abs(modalTop) + Math.abs(windowHeight) / 2;
        modalContainer.style.top = offset + "px";
      }
    }

    // Ajustar la posición del modal cuando se carga la página
    adjustModalPosition();

    return () => {};
  }, []);

  useEffect(() => {
    const M = document.getElementById(data.opciones.id_a);

    if (M) {
      M.scrollIntoView({ behavior: "smooth", block: "center" }); // antiguamente "center"
    }
  }, [data.opciones.id_a]);

  return ReactDOM.createPortal(
    <>
      <div
        className="pantalla-fondo-modal"
        onClick={() => handleClose(false)}
        style={{ display: d, zIndex }}
      ></div>

      <div
        id={data.opciones.id_a}
        className={`pantalla-modal-container ${className}`}
        style={modalContainerStyle}
        ref={modalRef}
      >
        <div
          style={{
            background: "white",
            padding: "0.3rem 0 ",
            display: "flex",
            flexDirection: "row-reverse",
            width: "100%",
          }}
        >
          <span
            style={{
              background: "lightgray",
              fontSize: "14px",
              padding: "0.3rem 0.6rem",
              cursor: "pointer",
            }}
            onClick={() => handleClose(false)}
          >
            X
          </span>
        </div>
        {children}
      </div>
    </>,
    document.getElementById("modal-root")
  );
}

export const MemoizedModal = React.memo(Modal);
