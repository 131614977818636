import React, { useContext, useState } from "react";
import { withTools } from "../../../../helper/withTools";
import FuncionesContext from "../../../../context/FuncionesContext";
import ModalesContext from "../../../../context/ModalContext";
import { TextField } from "@material-ui/core";
import PantallaContext from "../../../../context/PantallaContext";
import Label from "../LabelF";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  inputBComponent: {
    "& .MuiInputBase-input": {
      //   height: "1.4375em",
      //  padding: "13px 14px",
    },
    "& .css-nnbavb": {
      display: "none",
    },
  },
});

const InputBuscador = (props) => {
  const {
    id,
    id_a,
    setFiltrosAAplicar,
    error,
    setError,
    seleccion_multiple,
    columna_ida_valor,
    columna_ida_mostrar,
    filtro_ida_valor,
    pantalla_ida,
  } = props;

  const [termino, setTermino] = useState("");
  const [oid, setId] = useState("");
  const classes = useStyle();

  const handleInput = (e) => {
    const { value } = e.target;
    setTermino(value);
    setFiltrosAAplicar((prevState) => {
      return { ...prevState, [id_a]: undefined };
    });
    setId("");
  };

  const { escupirModal } = useContext(FuncionesContext);
  const { cerrarModal } = useContext(ModalesContext);

  const filtro = {
    [filtro_ida_valor]: termino, // filtro id_a a pasar al listado "MONODRO_LST_FILTRO_NOMBRE"
  };

  const opMultiple = {
    listado_seleccion: true,
    showSelectAllCheckbox: false,
    showTextRowsSelected: false,
    onSelectionChange: (e) => {
      const noid = e.map((o) => o[columna_ida_valor]); // columna a setear como value "MONODRO_LST_ID"
      setTermino(e.map((t) => t[columna_ida_mostrar]).toString()); // columnda a mostrar "MONODRO_LST_NOMBRE"
      setFiltrosAAplicar((prevState) => {
        return { ...prevState, [id_a]: noid };
      });
      setError((e) => {
        return { ...e, [id_a]: false };
      });
      setId(noid);
    },
    listado_filtro_termino: termino,
    filtrosDesdeInput: filtro,
    activarListado: !!termino,
  };

  const opSimple = {
    onRowClick: (e, rowData, data) => {
      const noid = rowData[columna_ida_valor]; // columna a setear como value
      setTermino(rowData[columna_ida_mostrar]); // columnda a mostrar
      setId(noid);
      setFiltrosAAplicar((prevState) => {
        return { ...prevState, [id_a]: noid };
      });
      setError((e) => {
        return { ...e, [id_a]: false };
      });
    },
    onRowDoubleClick: (e, rowData) => {
      const noid = rowData[columna_ida_valor]; // columna a setear como value
      setTermino(rowData[columna_ida_mostrar]); // columnda a mostrar
      setId(noid);
      setFiltrosAAplicar((prevState) => {
        return { ...prevState, [id_a]: noid };
      });
      setError((e) => {
        return { ...e, [id_a]: false };
      });
      cerrarModal(pantalla_ida);
    },
    rowStyle: true,
    listado_filtro_termino: termino,
    filtrosDesdeInput: filtro,
    activarListado: !!termino,
  };

  const showModal = () =>
    escupirModal(
      pantalla_ida, // Listado para abrir "PANTALLA_LIST_MONODRO"
      {},
      seleccion_multiple === "s" ? opMultiple : opSimple
    );

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      showModal();
    }
  };

  return (
    <div id={id_a}>
      <Label
        label={props.nombre}
        opcionales_null={props.opcionales_null}
        permite_null={props.permite_null}
      />

      <div className="filtroBuscador">
        <TextField
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          id={id}
          value={termino}
          style={{ width: "84%", padding: "0 0" }}
          type="text"
          error={error[id_a]}
          // variant="outlined"
          label={props.label}
          className={classes.inputBComponent}
        />
        <button
          onClick={() => showModal()}
          style={{
            padding: "0.23rem",
            backgroundColor: "#006F7A",
            color: "white",
            width: "15%",
            height: "100%",
          }}
          className="InputBusquedaLupa"
        >
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  );
};

export default withTools(InputBuscador);
