import React, { useContext, useState } from "react";
import FuncionesContext from "../../../context/FuncionesContext";
import TextFieldEditable from "../../../components/TextFieldEditable";
import "../Vista.scss";
import { validarRegex } from "../../../context/FuncionesContext";

const InputTextEditable = ({
  data,
  cab,
  hijos,
  campokey,
  indiceData,
  Context,
  id_elemento,
}) => {
  const { superSubmit } = useContext(FuncionesContext);

  const { Dispatch, opciones } = useContext(Context);

  const [value, setValue] = useState(data[campokey]);
  const [lastValue, setLastvalue] = useState(data[campokey]);

  const [error, setError] = useState(false);

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const handleCancelar = () => {
    setValue(data[campokey]);
    setLastvalue(data[campokey]);
  };

  const handleOnBlur = (e) => {
    if (validarRegex(e.target.value, cab.validacion_regex)) {
      setError(false);
      return;
    }
    setError(true);
  };

  const handleGuardar = async (e) => {
    const valor = e.target.value;

    if (valor === null || lastValue?.toString() === valor.toString().trim())
      return;

    const update_id = data[cab.update_id_alias];
    const { id_a } = cab;

    superSubmit({ valor, id_a, update_id, handleCancelar, cab, data })
      .then((result) => {
        setLastvalue(() => valor);

        Dispatch({
          type: "SET_DATO_ESPECIFICO",
          payload: {
            value: result.data.registro.id,
            indiceData,
            key: cab.update_id_alias,
          },
        });

        Dispatch({
          type: "SET_DATO_ESPECIFICO",
          payload: {
            value: valor,
            indiceData,
            key: cab.campo_alias ? cab.campo_alias : id_a,
          },
        });

        return result;
      })
      .catch((err) => {
        console.log("Cancelado ", err);
      });
  };

  const align = (() => {
    let clas = "align-left";

    if (cab.align?.trim() === "center") {
      clas = "align-center";
    }
    if (cab.align?.trim() === "right") {
      clas = "align-right";
    }

    return clas;
  })();

  const disabled = (() => {
    if (data[cab.id_a + "_disabled"]) {
      return data[cab.id_a + "_disabled"] === "s";
    }
    if (cab.disabled) {
      return cab.disabled === "s";
    }
    return false;
  })();

  return (
    <div id={id_elemento} className="tarjeta_grid_item_label_item">
      {opciones.tipo.id === 6 ? (
        <div className="vista_label">
          <p
            style={{
              maxHeight: "25px",
              position: "relative",
              top: "2px",
              wordBreak: "break-all",
            }}
            className="suW"
          >
            {nombre}:
          </p>
        </div>
      ) : (
        <></>
      )}
      <TextFieldEditable
        disabled={disabled}
        value={value}
        error={error}
        helperText={error ? cab.helperText : ""}
        lastValue={lastValue}
        setValue={setValue}
        onEnter={handleGuardar}
        onBlur={handleOnBlur}
        maxCaracteres={parseInt(cab.maximo_caracteres)}
        id={id_elemento}
        className={`_textFieldEditable ${align}`}
      />
    </div>
  );
};

export default InputTextEditable;
