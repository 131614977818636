import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import Label from "./LabelF";
import { validarRegex } from "../../../context/FuncionesContext";

const InputText = ({ setValor, valor, cab, error, setError, data }) => {
  const { id, id_a, margin_bottom_abm, pass_minimo } = cab;

  const [confirmar, setConfirmar] = useState("");
  const [gotBlur, setBlur] = useState(false);

  const handleInput = (e) => {
    let { value } = e.target;

    setValor(value);

    if (pass_minimo) {
      if (value.length < pass_minimo) {
        return setError((e) => {
          return { ...e, [id_a]: true };
        });
      }
    }

    setError((e) => {
      return { ...e, [id_a]: false };
    });

    if (value !== confirmar) {
      return setError((e) => {
        return { ...e, [id_a + "_confirm"]: true };
      });
    }

    setError((e) => {
      return { ...e, [id_a + "_confirm"]: false };
    });
  };

  const handleConfirmInput = (e) => {
    setConfirmar(e.target.value);
    if (valor !== confirmar) {
      return setError((e) => {
        return { ...e, [id_a + "_confirm"]: false };
      });
    }
  };

  const handleOnBlur = (e) => {
    if (validarRegex(e.target.value, cab.validacion_regex)) {
      setError((e) => {
        return { ...e, [id_a]: false };
      });
      return;
    }
    setError((e) => {
      return { ...e, [id_a]: true };
    });
  };

  const style_input_abm = {
    width: "100%",
    marginBottom: margin_bottom_abm,
  };

  return (
    <>
      {cab.nombre ? (
        <Label
          label={cab.nombre}
          opcionales_null={cab.opcionales_null}
          permite_null={cab.permite_null}
        />
      ) : (
        <></>
      )}
      <div
        className={`${data[cab.id_a + "_className"] ?? cab.className}`}
        style={{
          width: cab.width ?? "100%",
        }}
      >
        <div style={{ marginBottom: "10px" }}>
          <TextField
            id={id}
            type="password"
            placeholder={cab.placeholder}
            onChange={handleInput}
            onBlur={(e) => {
              setBlur(true);
              handleOnBlur(e);
            }}
            defaultValue={data[id_a]}
            variant="outlined"
            size="small"
            label={cab.label}
            value={valor ? valor : ""}
            error={error[id_a]}
            helperText={error[id_a] ? cab.helper_text : ""}
            style={style_input_abm}
          />
        </div>
        <div>
          <TextField
            id={id + "_confirm"}
            type="password"
            onChange={handleConfirmInput}
            onBlur={(e) => setBlur(true)}
            label="Confirmar Password"
            variant="outlined"
            size="small"
            value={confirmar}
            error={valor !== confirmar && gotBlur}
            helperText={
              valor !== confirmar && gotBlur ? (
                <p>
                  Las contraseñas no coinciden{" "}
                  <span role="img" aria-label="emoji">
                    &#9940;
                  </span>
                </p>
              ) : (
                ""
              )
            }
            style={style_input_abm}
          />
        </div>
      </div>
    </>
  );
};
export default InputText;
