import React from "react";
import { Button } from "@material-ui/core";
import "../../botonera.scss";
import { fontTheme } from "../../../helper/themeMui";

const Cancelar = ({ handleCancelar }) => {
  return (
    <>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleCancelar}
          style={{
            height: "fit-content",
            marginRight: "20px",
            borderColor: "#006f7a",
            color: "#006f7a",
            fontFamily: fontTheme.typography.fontFamily,
          }}
        >
          Cancelar
        </Button>
    </>
  );
};

export default Cancelar;
