import React, { createContext, useContext, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { farmageo_api } from "../../../config";
import axios from "axios";
import AlertasContext from "./AlertaContext";
import PantallaContext from "./PantallaContext";
import ModalesContext from "./ModalContext";
const S = require("sweetalert2");

const FuncionesContext = createContext();

export const ALERT = ({
  title,
  text,
  icon,
  confirmButtonText,
  timer = 5000,
  denyButtonText,
}) => {
  return S.fire({
    title: title,
    text: text,
    icon: icon,
    confirmButtonText: confirmButtonText && confirmButtonText,
    timer: timer,
    denyButtonText: denyButtonText && denyButtonText,
    showDenyButton: denyButtonText && true,
    allowOutsideClick: true,
    allowEscapeKey: true,
  });
};

const alertCargando = () =>
  S.fire({
    title: "Cargando", // Título de la ventana emergente
    html: '<div class="spinner"></div>', // Contenido HTML con el spinner
    showCancelButton: false, // Ocultar botón de cancelar
    showConfirmButton: false, // Ocultar botón de confirmar
    allowOutsideClick: false, // No permitir hacer clic fuera de la ventana emergente
    onBeforeOpen: () => {
      S.showLoading(); // Mostrar el spinner
    },
    timer: 40000,
    didOpen: () => {
      alertCargando.id = "cargando";
    },
  });

const alertarError = async (mensaje) => {
  return ALERT({
    title: "Error",
    text: mensaje,
    icon: "error",
    timer: 10000,
    confirmButtonText: "Aceptar",
  });
};

const alertarMensaje = async (mensaje) => {
  return await S.fire({
    position: "top-end",
    icon: "info",
    text: mensaje,
    showConfirmButton: false,
    timer: 5000,
  });
};

export const requestErrorHandler = async (res) => {
  if (res.status < 400) return res;
  return alertarError(res.data.error.message);
};

export function requisitosRegexp(expresionRegular) {
  const condiciones = [];

  // Verificar si la expresión acepta solo letras
  if (expresionRegular.test(/^[a-zA-Z]+$/)) {
    condiciones.push("Solo se permiten letras.");
  }

  // Verificar si la expresión acepta solo mayúsculas o solo minúsculas
  if (expresionRegular.test(/^[A-Z]+$/)) {
    condiciones.push("Solo se permiten letras mayúsculas.");
  } else if (expresionRegular.test(/^[a-z]+$/)) {
    condiciones.push("Solo se permiten letras minúsculas.");
  }

  // Verificar si la expresión acepta solo números
  if (expresionRegular.test(/^\d+$/)) {
    condiciones.push("Solo se permiten números.");
  }

  // Verificar si se requiere un mínimo de caracteres
  const minimoCaracteres = expresionRegular.toString().match(/{(\d+),/);
  if (minimoCaracteres) {
    condiciones.push(
      `Se requiere un mínimo de ${minimoCaracteres[1]} caracteres.`
    );
  }

  // Verificar si hay un máximo de caracteres
  const maximoCaracteres = expresionRegular.toString().match(/{\d+,(\d+)}/);
  if (maximoCaracteres) {
    condiciones.push(
      `Se permite un máximo de ${maximoCaracteres[1]} caracteres.`
    );
  }

  // Generar el mensaje descriptivo de las condiciones
  return condiciones.length > 0
    ? `El string debe cumplir las siguientes condiciones: ${condiciones.join(
        " "
      )}`
    : "No hay condiciones específicas.";
}

export const makeRegexp = (reg) => {
  const param = reg.split("||");
  return new RegExp(param[0], param[1]);
};

export const validarRegex = (valor, validacion_regex) => {
  if (!validacion_regex || validacion_regex === "") return true;
  const regexp = makeRegexp(validacion_regex);

  if (regexp.test(valor)) {
    return true;
  }

  return false;
};

export const FuncionesProvider = (props) => {
  const history = useHistory();
  const { ALERT } = useContext(AlertasContext);
  const { PantallaDispatch, pantalla, configuraciones, configuraciones_ref } =
    useContext(PantallaContext);
  const modalContext = useContext(ModalesContext);

  const pedirConfirmacion = async (props) => {
    const { cab, data } = props;

    const mensaje = (() => {
      if (data && data[cab.id_a + "_alerta_confirmar_texto"]) {
        return data[cab.id_a + "_alerta_confirmar_texto"];
      }
      if (cab.alerta_confirmar_texto) {
        return cab.alerta_confirmar_texto;
      }
      return "";
    })();

    const titulo = (() => {
      if (data && data[cab.id_a + "_alerta_confirmar_titulo"]) {
        return data[cab.id_a + "_alerta_confirmar_titulo"];
      }
      if (cab.alerta_confirmar_titulo) {
        return cab.alerta_confirmar_titulo;
      }
      return "";
    })();

    return ALERT({
      title: titulo,
      text: mensaje,
      icon: "question",
      denyButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
    });
  };

  const alertarExito = async (res, cab, data) => {
    let { alerta_titulo, alerta_texto } = cab;

    if (data) {
      const alerta_titulo_alias = cab.id_a + "_alerta_titulo";
      const alerta_texto_alias = cab.id_a + "_alerta_texto";
      if (data[alerta_texto_alias]) {
        alerta_texto = data[alerta_texto_alias];
      }
      if (data[alerta_titulo_alias]) {
        alerta_titulo = data[alerta_titulo_alias];
      }
    }

    return ALERT({
      title: alerta_titulo ?? "Exito",
      text: res.msg ?? alerta_texto ?? "La accion se ha completado con exito",
      icon: "success",
      confirmButtonText: "Aceptar",
    });
  };

  const superSubmit = async ({
    valor,
    id_a,
    update_id,
    handleCancelar,
    cab,
    data,
    indiceData,
  }) => {
    if (!update_id || update_id === "") {
      if (cab.alerta_confirmar === "s") {
        return await insertarConConfirmacion({
          valor,
          id_a,
          update_id,
          handleCancelar,
          cab,
          data,
          indiceData,
        });
      }

      return await insertarSinConfirmar({
        valor,
        id_a,
        update_id,
        handleCancelar,
        cab,
        data,
        indiceData,
      });
    }

    if (cab.alerta_confirmar === "s") {
      return await guardarConConfirmacion({
        valor,
        id_a,
        update_id,
        handleCancelar,
        cab,
        data,
        indiceData,
      });
    }
    return await guardarSinConfirmar({
      valor,
      id_a,
      update_id,
      handleCancelar,
      cab,
      data,
      indiceData,
    });
  };

  const guardarSinConfirmar = async (props) => {
    const { valor, id_a, update_id, handleCancelar, cab, data, indiceData } =
      props;

    alertCargando();
    return await guardar({
      valor,
      id_a,
      update_id,
      handleCancelar,
      cab,
      data,
      indiceData,
    })
      .then((res) => {
        S.close();
        return handleResponse({ response: res, cab, data });
      })
      .catch((err) => {
        S.close();
        handleCancelar();
        requestErrorHandler(err);
        throw err;
      });
  };

  const guardarConConfirmacion = async (props) => {
    const { data, cab } = props;

    return pedirConfirmacion({ data, cab }).then(async (result) => {
      if (!result.isConfirmed) {
        props.handleCancelar();
        throw result;
      }
      return await guardarSinConfirmar(props);
    });
  };

  const insertarSinConfirmar = async (props) => {
    const { valor, id_a, update_id, handleCancelar, cab, data, indiceData } =
      props;
    alertCargando();
    return await insertar({
      valor,
      id_a,
      update_id,
      handleCancelar,
      cab,
      data,
      indiceData,
      insert_ids: data[cab.insert_ids_alias] ?? cab.insert_ids,
    })
      .then((res) => {
        S.close();
        return handleResponse({ response: res, cab, data });
      })
      .catch((err) => {
        S.close();
        handleCancelar();
        requestErrorHandler(err);
        throw err;
      });
  };

  const insertarConConfirmacion = async (props) => {
    const { data, cab } = props;

    return pedirConfirmacion({ data, cab }).then(async (result) => {
      if (!result.isConfirmed) {
        props.handleCancelar();
        throw result;
      }
      return await insertarSinConfirmar(props);
    });
  };

  const guardar = async ({ valor, update_id, id_a }) => {
    if (!update_id || !id_a) {
      throw { message: "No hay update_id o id_a", id_a, update_id };
    }

    return await axios.post(farmageo_api + "/guardar", {
      valor,
      update_id,
      id_a,
    });
  };

  const guardarEP = async ({ data, cab, indiceData, handleCancelar }) => {
    const { id_a, update_id_alias } = cab;
    const update_id = data[update_id_alias];

    if (!update_id || !id_a) {
      throw { message: "No hay update_id o id_a", id_a, update_id };
    }

    try {
      axios
        .post(farmageo_api + "/guardar", {
          update_id,
          id_a,
        })
        .then((res) => {
          if (res.status >= 400) {
            requestErrorHandler(res);
            throw res.data;
          }
          if (cab.alerta_exito === "s") {
            alertarExito(res, cab, data);
          }
          if (
            cab.refrescarConfiguracion &&
            cab.refrescarConfiguracion.trim() !== ""
          ) {
            refrescarConfiguracion({ cab });
          }
          return res;
        });
    } catch (err) {
      handleCancelar();
      requestErrorHandler(err);
      throw err;
    }
  };

  const insertar = async ({ valor, id_a, insert_ids }) => {
    return await axios.post(farmageo_api + "/insertar", {
      valor,
      id_a,
      insert_ids,
    });
  };

  const subirArchivo = async ({
    archivo,
    valor,
    handleCancelar,
    cab,
    data,
    indiceData,
  }) => {
    const formData = new FormData();
    formData.append("archivo", archivo);
    formData.append("valor", valor);
    formData.append("insert_ids", data[cab.insert_ids_alias]);
    formData.append("update_id", data[cab.update_id_alias]);
    formData.append("id_a", cab.id_a);

    if (cab.alerta_confirmar === "s") {
      const confirmacion = await pedirConfirmacion();
      if (!confirmacion.isConfirmed) {
        handleCancelar();
      }
    }
    const ruta = !data[cab.update_id_alias] ? "/insertar" : "/guardar";

    return await axios
      .post(farmageo_api + ruta, formData)
      .then((res) => {
        return handleResponse({ response: res, cab, data });
      })
      .catch((err) => {
        handleCancelar();
        requestErrorHandler(err);
        throw err;
      });
  };

  const eliminar = async ({ id_a, delete_id }) => {
    return await axios.post(farmageo_api + "/eliminar", {
      id_a,
      delete_id,
    });
  };

  const refrescarConfiguracion = async ({ cab }) => {
    if (!cab.refrescarConfiguracion)
      throw new Error("No hay cab.refrescarConfiguracion");
    try {
      const ids = cab.refrescarConfiguracion.split(",");
      ids.forEach((id) => {
        if (!configuraciones_ref[id]) return;
        PantallaDispatch({
          type: "REFRESCAR",
          payload: { id: id.trim(), ref: refrescarConfiguracion },
        });
      });
      return "sin problemas";
    } catch (er) {
      console.log(er);
      return er;
    }
  };

  const getConfiguracion = async (id_a, qsBody, params = {}) => {
    params.pantalla = id_a;
    return axios
      .post(
        farmageo_api + "/config/" + pantalla,
        Object.assign(qsBody, params),
        { params }
      )
      .then((res) => {
        //console.log(res);
        return res;
      });
  };

  const getPantalla = async (id_a, id, params, qsBody) => {
    params.pantalla = id_a;
    return axios
      .post(
        farmageo_api + "/pantalla/" + id_a,
        Object.assign({ id }, qsBody, params),
        {
          params,
        }
      )
      .then((res) => {
        return res;
      });
  };

  const checkID_A = (string) => {
    const regex = new RegExp("(^[A-Z]+(_[A-Z]+)*)$", "g");

    const esID_A = regex.test(string);
    return esID_A;
  };

  const ABMSubmit = async ({ opciones, id_a, qsBody, params, setLoading }) => {
    const { endpoint, alerta_confirmar } = opciones;
    try {
      let confirmado = true;

      if (alerta_confirmar)
        confirmado = (await pedirConfirmacion({ cab: opciones })).isConfirmed;

      if (!confirmado) throw { code: "cancelado", status: 200 };

      setLoading(true);

      const res = await putConfiguracion(id_a, qsBody, params, endpoint);

      setLoading(false);
      if (res.status > 400) {
        throw res;
      }
      if (res.status < 400) {
        await manejarAbmRespuesta.despachar(opciones, res);
      }
      return handleResponse({
        response: res,
        cab: opciones,
        data: res.registro, // deberia ser la data de la conf
      });
    } catch (res) {
      console.log("error abm submit", res);
      PantallaDispatch({
        type: "ADD_SQL",
        payload: res.data?.sql,
      });

      if (res.code === "cancelado") return res;
      alertarError(res.data?.error.message);
      return res;
    }
  };

  class manejarAbmRespuesta {
    constructor(opciones, respuesta) {
      this.res = respuesta;
      this.opciones = opciones;
    }

    async despachoModal() {
      const { enlace_siguiente, alerta_exito } = this.opciones;

      if (alerta_exito) await alertarExito(this.res, this.opciones);

      if (
        this.opciones.refrescarConfiguracion &&
        this.opciones.refrescarConfiguracion.trim() !== ""
      ) {
        return refrescarConfiguracion({ cab: this.opciones });
      }

      if (enlace_siguiente)
        return redireccionar({ cab: this.opciones, res: this.res });
    }

    async despacho() {
      const { enlace_siguiente, alerta_exito } = this.opciones;

      if (alerta_exito) await alertarExito(this.res, this.opciones);

      if (enlace_siguiente)
        return redireccionar({ cab: this.opciones, res: this.res });
      if (
        this.opciones.refrescarConfiguracion &&
        this.opciones.refrescarConfiguracion.trim() !== ""
      ) {
        refrescarConfiguracion({ cab: this.opciones });
      }
    }

    static async despachar(opciones, respuesta) {
      const abmRes = new manejarAbmRespuesta(opciones, respuesta);

      if (opciones.esModal) {
        return await abmRes.despachoModal();
      }
      return await abmRes.despacho();
    }
  }

  const redireccionar = async ({ cab, data, res }) => {
    const enlace_siguiente_pasar_id = cab.enlace_siguiente_pasar_id === "s";
    const id_nombre = cab.update_id_nombre ?? "id";

    if (checkID_A(cab.enlace_siguiente)) {
      const location = {
        pathname: `/Configuracion/${cab.enlace_siguiente}`,
        search: enlace_siguiente_pasar_id
          ? `?&id=${res.data.registro[id_nombre]}`
          : undefined,
      };

      history.push(location);
      return window.location.reload();
    }

    return history.push({
      pathname: `${cab.enlace_siguiente}`,
    });
  };

  const putConfiguracion = async (
    id_a,
    qsBody,
    body,
    endpoint = "/insertar"
  ) => {
    body.id_a = id_a;
    body.id = qsBody?.id;
    return axios.post(farmageo_api + endpoint, body);
  };

  const eliminarRegistro = async (props) => {
    const { data, cab, indiceData } = props;

    cab.alerta_confirmar_texto =
      cab.alerta_confirmar_texto ?? "Esta acción no se puede deshacer.";

    const result = await pedirConfirmacion({ data, cab });

    if (!result.isConfirmed) {
      props.handleCancelar();
      throw result;
    }
    try {
      const response = await eliminar({
        id_a: cab.id_a,
        delete_id: data[cab.delete_id_alias],
      });

      return handleResponse({ response, cab, data });
    } catch (err) {
      ALERT({
        title: "Error",
        text: err.message,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
      return err;
    }
  };

  const setAtributo = ({ data, cab, indiceData, handleCancelar, dispatch }) => {
    // eslint-disable-next-line no-unused-expressions
    cab.efecto_objetivo?.split(",").forEach((id_a) => {
      const efecto_atributo = cab.efecto_atributo?.split(",");

      if (!efecto_atributo || efecto_atributo.length !== 2) return;

      dispatch({
        type: "SET_DATO_ESPECIFICO",
        payload: {
          key: id_a + "_" + efecto_atributo[0],
          indiceData: indiceData,
          value: efecto_atributo[1],
        },
      });

      // const el = document.getElementById(id_a + indiceData);
      // el.disabled = true;
      // el.classList.add("input-disabled");
    });
  };

  function transformarTipoDato(valor, tipoObjetivo) {
    switch (tipoObjetivo) {
      case "string":
        valor = String(valor);
        break;
      case "number":
        valor = Number(valor);
        break;
      case "boolean":
        valor = Boolean(valor);
        break;
      // Agrega más casos según tus necesidades
      default:
        break;
    }
    return valor;
  }

  const escupirModal = async (id_a, data, propsModal, qsBody) => {
    try {
      const conf = await getPantalla(id_a, data.id, data, qsBody);
      const { addModal } = modalContext;
      conf.data.opciones.modal = true;
      addModal({
        id_a,
        data,
        parametro_id: data.id,
        idx: configuraciones.length,
        propsModal,
        qsBody,
      });
      PantallaDispatch({ type: "ADD_CONFIGURACION", payload: conf.data });
    } catch (err) {
      console.log(err);
      return;
    }
  };

  function nada(p) {
    console.log("funcion que no hace nada: ", p);
  }

  async function endpoint(p) {
    const { cab, data, sideData } = p;
    try {
      if (data.length === 0)
        // eslint-disable-next-line no-throw-literal
        throw {
          code: "NO HAY DATA SELECCIONADA",
          msg: "No esta enviando ningun dato.",
        };

      if (cab.alerta_confirmar === "s") {
        const confirmacion = await pedirConfirmacion({ cab, data });

        if (!confirmacion.isConfirmed) {
          return p.handleCancelar();
        }
      }

      axios
        .post(farmageo_api + cab.endpoint, { data, sideData })
        .then((response) => {
          return handleResponse({ response, cab, data });
        });
    } catch (err) {
      console.log(err);
      ALERT({
        text: err.msg,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }

  async function endpointToHtml(p) {
    const { cab, data } = p;
    try {
      if (cab.alerta_confirmar === "s") {
        const confirmacion = await pedirConfirmacion({ cab, data });

        if (!confirmacion.isConfirmed) {
          return p.handleCancelar();
        }
      }

      axios.post(farmageo_api + cab.endpoint, data).then((response) => {
        handleResponse({ response, cab, data });

        const div = document.createElement("div");
        div.innerHTML = response.data.toString();

        const el = document.getElementById(cab.endpoint_insertarHTML_en);

        el.appendChild(div);
      });
    } catch (err) {
      console.log(err);
      ALERT({
        text: err.msg,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }

  const handleResponse = ({ response, cab, data }) => {
    if (response.status >= 400) {
      return requestErrorHandler(response);
    }
    if (cab.alerta_exito === "s") {
      alertarExito(response, cab, data);
    }
    if (response.data.message && response.data.message !== "") {
      alertarMensaje(response.data.message);
    }
    if (
      cab.refrescarConfiguracion &&
      cab.refrescarConfiguracion.trim() !== ""
    ) {
      refrescarConfiguracion({ cab });
    }
    return response;
  };

  return (
    <FuncionesContext.Provider
      value={{
        insertar,
        insertarConConfirmacion,
        insertarSinConfirmar,
        guardarEP,
        guardar,
        guardarSinConfirmar,
        guardarConConfirmacion,
        refrescarConfiguracion,
        getConfiguracion,
        putConfiguracion,
        eliminarRegistro,
        requestErrorHandler,
        superSubmit,
        ABMSubmit,
        subirArchivo,
        checkID_A,
        escupirModal,
        nada,
        endpoint,
        endpointToHtml,
        setAtributo,
        transformarTipoDato,
        requisitosRegexp,
      }}
    >
      {props.children}
    </FuncionesContext.Provider>
  );
};

export default FuncionesContext;

export function parseQueryString(queryString) {
  if (!queryString) return {};
  const params = {};
  const pairs = queryString.substring(1).split("&");

  for (const pair of pairs) {
    const [key, value] = pair.split("=");
    params[key] = value;
  }

  return params;
}
