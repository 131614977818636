import React from "react";
import { TextField } from "@material-ui/core";
import Label from "./LabelF";
import { validarRegex } from "../../../context/FuncionesContext";

const InputText = ({
  setValor,
  valor,
  cab,
  error,
  setError,
  data,
  number,
  textarea,
}) => {
  const { id, id_a, label, margin_bottom_abm, width } = cab;

  const handleInput = (e) => {
    let { value } = e.target;

    if (cab.solo_mayus === "s") {
      value = value.toUpperCase();
    }
    setValor(value);
    setError((e) => {
      return { ...e, [id_a]: false };
    });
  };

  const handleOnBlur = (e) => {
    if (validarRegex(e.target.value, cab.validacion_regex)) {
      setError((e) => {
        return { ...e, [id_a]: false };
      });
      return;
    }
    setError((e) => {
      return { ...e, [id_a]: true };
    });
  };

  const style_input_abm = {
    width: "100%",
    marginBottom: margin_bottom_abm,
  };
  return (
    <>
      {cab.nombre ? (
        <Label
          label={cab.nombre}
          opcionales_null={cab.opcionales_null}
          permite_null={cab.permite_null}
        />
      ) : (
        <></>
      )}
      <TextField
        id={id}
        type={cab.input_type ?? (number ? "number" : "text")}
        placeholder={cab.placeholder}
        onChange={handleInput}
        onBlur={handleOnBlur}
        helperText={error[id_a] ? cab.helper_text : ""}
        defaultValue={data[id_a]}
        label={cab.label}
        value={valor ? valor : ""}
        error={error[id_a]}
        multiline={textarea}
        maxRows={500}
        minRows={2}
        variant="outlined"
        size="small"
        inputProps={{
          style: {
            textTransform: cab.solo_mayus === "s" ? "uppercase" : "inherit",
          },
          maxLength: cab.maximo_caracteres,
        }}
        style={style_input_abm}
        disabled={
          data[cab.id_a + "_disabled"]
            ? data[cab.id_a + "_disabled"] === "s"
            : cab.disabled === "s"
        }
      />
    </>
  );
};
export default InputText;
