import React, { useContext } from "react";
import FuncionesContext from "../context/FuncionesContext";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import { parseQueryString } from "../context/FuncionesContext";

export function Mensaje(props) {
  const { modalProps, configuracion, qsBody, params = {} } = props;
  const { opciones } = configuracion;
  const { mensaje, enlace_id_a, className, id_a } = opciones;

  const func = useContext(FuncionesContext);

  const search = "?" + enlace_id_a?.split("?")[1];
  const enlace = enlace_id_a?.split("?")[0];

  const styles = {
    gridColumn: opciones.grid_span ?? "1 / -1",
    border: "none",
    marginBottom: 0,
  };
  if (enlace_id_a && enlace_id_a !== "") {
    if (opciones.target === "modal") {
      return (
        <Card id={id_a} style={styles}>
          <div
            onClick={() =>
              func.escupirModal(
                enlace,
                params,
                {},
                Object.assign(qsBody, parseQueryString(search))
              )
            }
            className={className}
            id={id_a}
            style={{ cursor: "pointer" }}
          >
            {mensaje}
          </div>
        </Card>
      );
    }
    return (
      <Card id={id_a} style={styles}>
        <div className={className}>
          <Link
            to={{ pathname: `/pantalla/${enlace}`, search }}
            target={opciones.target}
          >
            {mensaje}
          </Link>
        </div>
      </Card>
    );
  }
  return (
    <Card id={id_a} style={styles}>
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: mensaje }}
      ></div>
    </Card>
  );
}
