import React, { useEffect } from "react";
import styles from "../helper/Style.module.css";

export const withTools = (Componente) => (props) => {
  const { data, cab, indiceData, id_elemento } = props;

  useEffect(() => {
    const g = document.getElementById(id_elemento)?.parentNode;
    if (g) {
      if (cab.tooltip_texto && cab.tooltip_texto !== "") {
        const id_tooltip = id_elemento + "_tooltip" + indiceData;

        // const observer = new IntersectionObserver((entries, observer) => {
        //   entries.forEach((e) => {
        //     const rightLimit = e.rootBounds.right;
        //     const rightObj = e.boundingClientRect.right;
        //     const leftLimit = e.rootBounds.left;
        //     const leftObj = e.boundingClientRect.left;

        //     if (rightObj > rightLimit) {
        //       const t = document.getElementById(id_tooltip);
        //       t.style.left = `-${rightObj - rightLimit - 30}px`;
        //     }
        //     if (leftObj < leftLimit) {
        //       const t = document.getElementById(id_tooltip);
        //       t.style.left = `${leftObj - leftLimit + 30}px`;
        //     }
        //   });
        // });

        const tooltip = document.createElement("span");
        tooltip.setAttribute("aria-hidden", "true");
        tooltip.id = id_tooltip;
        tooltip.appendChild(
          document.createTextNode(
            `${data[cab.id_a + "_tooltip_texto"] ?? cab.tooltip_texto}`
          )
        );

        tooltip.classList.add("Ftooltip"); // Pantalla.scss
        g.style.position = "relative";

        if (
          data[cab.id_a + "_tooltip_class"] === "verde" ||
          cab.tooltip_class === "verde"
        ) {
          tooltip.classList.add(styles.bg_verde);
          tooltip.classList.add("Ftooltip_verde");
        }

        if (
          data[cab.id_a + "_tooltip_class"] === "rojo" ||
          cab.tooltip_class === "rojo"
        ) {
          //tooltip.style.color = "black";
          tooltip.classList.add(styles.bg_rojo);
          tooltip.classList.add("Ftooltip_rojo");
        }

        if (data[cab.id_a + "_tooltip_class"]) {
          tooltip.classList.add(data[cab.id_a + "_tooltip_class"]);
        }

        const showTooltip = (e) => {
          tooltip.setAttribute("aria-hidden", "false");
          g.appendChild(tooltip);

          //  observer.observe(tooltip);
          const tooltipWidth = tooltip.offsetWidth;
          const tooltipHeight = tooltip.offsetHeight;

          const elementRect = g.getBoundingClientRect();
          const elementX = elementRect.x;
          const elementY = elementRect.y;

          let tooltipX = elementX + elementRect.width / 2;
          let tooltipY = elementY + elementRect.height / 2;

          if (tooltipX < 0) {
            tooltipX = 0;
          } else if (tooltipX + tooltipWidth > window.innerWidth) {
            //  tooltipX = window.innerWidth; // - tooltipWidth * 0.25;
          }

          if (tooltipY < 0) {
            tooltipY = 0;
          } else if (tooltipY + tooltipHeight > window.innerHeight) {
            //  tooltipY = window.innerHeight - tooltipHeight;
          }

          tooltip.style.left = `${tooltipX}px`;
          tooltip.style.top = `${tooltipY}px`;
        };

        const removeTooltip = (e) => {
          const t = document.getElementById(id_tooltip);
          tooltip.setAttribute("aria-hidden", "true");
          //  observer.unobserve(t);

          if (t) {
            t.remove();
          }
        };

        g.addEventListener("mouseenter", (e) => {
          e.stopPropagation();
          showTooltip(e);
        });

        g.addEventListener("mouseleave", (e) => {
          e.stopPropagation();
          removeTooltip(e);
        });
        window.addEventListener("scroll", removeTooltip);
      }
    }
  }, [props]);

  return (
    <>
      <Componente {...props} />
    </>
  );
};
