import React, { useState, useContext } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ar from "date-fns/locale/es";
import FuncionesContext from "../../../context/FuncionesContext";
registerLocale("ar", ar);

function parseISOString(s) {
  if (!s) return;
  var b = s.split(/\D+/);
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

const InputDate = ({
  data,
  cab,
  campokey,
  indiceData,
  id_elemento,
  Context,
}) => {
  const { superSubmit } = useContext(FuncionesContext);
  const { dispatch, opciones } = useContext(Context);

  const [value, setValue] = useState(parseISOString(data[campokey]));
  const [lastValue, setLastvalue] = useState(parseISOString(data[campokey]));

  const permite_null =
    data[cab.id_a + "_permite_null"] === "s" || cab.permite_null === "s";
  const disabled = data[cab.id_a + "_disabled"] === "s" || cab.disabled === "s";

  const nombre = (() => {
    if (opciones.tipo.id === 2) return false;
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const className = data[cab.id_a + "_className"] ?? cab.className;

  const handleCancelar = () => {
    setValue(parseISOString(data[campokey]));
    setLastvalue(parseISOString(data[campokey]));
  };

  const handleGuardar = async (date) => {
    const valor = date;

    if (!permite_null) {
      if (valor === null) return;
    }
    if (lastValue?.toDateString() === valor?.toDateString()) return;

    const valorParseado =
      valor === null
        ? null
        : valor.toISOString().replace(/T/, " ").replace(/\..+/, "");

    setValue(valor);
    const update_id = data[cab.update_id_alias];
    const { id_a } = cab;

    await superSubmit({
      valor: valorParseado,
      id_a,
      update_id,
      handleCancelar,
      cab,
      data,
      indiceData,
    })
      .then((result) => {
        setLastvalue(value);

        dispatch({
          type: "SET_DATO_ESPECIFICO",
          payload: {
            value: result.data.registro.id,
            indiceData,
            key: cab.update_id_alias,
          },
        });

        dispatch({
          type: "SET_DATO_ESPECIFICO",
          payload: {
            valor: value?.toISOString().replace(/T/, " ").replace(/\..+/, ""),
            indiceData,
            key: cab.campo_alias ? cab.campo_alias : id_a,
          },
        });

        return result;
      })
      .catch((err) => {
        console.log("Cancelado ", err);
      });
  };

  return (
    <div id={id_elemento} className="tarjeta_grid_item_label_item">
      {nombre ? <label className="vista_label">{nombre}:</label> : <></>}
      <DatePicker
        selected={value}
        onChange={handleGuardar}
        disabled={disabled}
        isClearable={permite_null}
        dateFormat={cab.formato_fecha ?? "dd-MM-yyyy"}
        locale="ar"
        placeholderText=" sin fecha "
        withPortal={opciones.tipo.id === 2}
        showYearDropdown
        className={`input_fecha ${className}`}
      />
    </div>
  );
};

export default InputDate;
