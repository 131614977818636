import React, { useContext, useState } from "react";
import { withTools } from "../../../../helper/withTools";
import FuncionesContext from "../../../../context/FuncionesContext";
import ModalesContext from "../../../../context/ModalContext";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import ABMContext from "../../../context/ABMContext";

const useStyle = makeStyles({
  inputBComponent: {
    "& .MuiInputBase-input": {
      //   height: "1.4375em",
      //  padding: "13px 14px",
    },
    "& .css-nnbavb": {
      display: "none",
    },
  },
});

const InputBuscador = (props) => {
  const { data, cab, qsBody, valor, setValor } = props;
  const {
    seleccion_multiple,
    columna_ida_valor,
    columna_ida_mostrar,
    filtro_ida_valor,
    pantalla_ida,
  } = cab;

  // const { superSubmit } = useContext(FuncionesContext);

  const [termino, setTermino] = useState(valor);
  const classes = useStyle();
  const [select_data, setSelectData] = useState({});

  const handleInput = (e) => {
    const { value } = e.target;
    setTermino(value);
  };

  const { escupirModal } = useContext(FuncionesContext);
  const { cerrarModal } = useContext(ModalesContext);
  const { valorFormulario } = useContext(ABMContext);

  const filtro = {
    [filtro_ida_valor]: termino, // filtro id_a a pasar al listado "MONODRO_LST_FILTRO_NOMBRE"
  };

  //pasar como filtro
  // eslint-disable-next-line no-unused-expressions
  cab.pasar_input_como_filtro?.split(",").forEach((ida, i) => {
    if (valorFormulario) {
      filtro[cab.filtros_ida?.split(",")[i]] = valorFormulario[ida];
    }
  });

  class handleSubmit {
    // const data_send = data_selected.map((d) => {
    //   return d[columna_ida_valor];
    // });
    constructor(e, row, data_selected) {
      this.data_selected = data_selected;
      this.row = row;
      this.event = e;
    }

    static submitMultiple(data_selected) {
      setValor(data_selected);
      cerrarModal(pantalla_ida);
    }

    static submitSimple() {
      cerrarModal(pantalla_ida);
    }
  }

  const opMultiple = {
    listado_seleccion: "s",
    showSelectAllCheckbox: false,
    showTextRowsSelected: false,
    onSelectionChange: (rowData) => {
      const noid = rowData.map((o) => o[columna_ida_valor]); // columna a setear como value "MONODRO_LST_ID"
      setTermino(rowData.map((t) => t[columna_ida_mostrar]).toString()); // columnda a mostrar "MONODRO_LST_NOMBRE"
      //funcion de guardar
      setSelectData(() => {
        return rowData;
      });
    },
    listado_filtro_termino: termino,
    filtrosDesdeInput: filtro,
    activarListado: !!termino,
    iniciar_activo: "n",
    submit_button: true,
    submit_texto: "Seleccionar",
    submit_button_handleSubmit: handleSubmit.submitMultiple,
  };

  const opSimple = {
    onRowClick: (e, rowData, data) => {
      const noid = rowData[columna_ida_valor]; // columna a setear como value
      setTermino(rowData[columna_ida_mostrar]); // columnda a mostrar
      setValor(noid);
      setSelectData(noid);
    },
    onRowDoubleClick: (e, rowData) => {
      const noid = rowData[columna_ida_valor]; // columna a setear como value
      setTermino(rowData[columna_ida_mostrar]); // columnda a mostrar
      setValor(noid);
      setSelectData(noid);
      cerrarModal(pantalla_ida);
    },
    rowStyle: true,
    listado_filtro_termino: termino,
    filtrosDesdeInput: filtro,
    activarListado: !!termino,
    // activarListado: "s",
    iniciar_activo: "n", // como en realidad llama a la pantalla, el atributo inicial del listado es quien prevalece
    submit_button: true,
    submit_texto: "Seleccionar",
    submit_button_handleSubmit: handleSubmit.submitSimple, //handleSubmit.submitSimple,
  };

  const showModal = () => {
    escupirModal(
      pantalla_ida, // Listado para abrir "PANTALLA_LIST_MONODRO"
      {},
      seleccion_multiple === "s" ? opMultiple : opSimple,
      qsBody
    );
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      showModal();
    }
  };

  return (
    <div id={cab.id_a} style={{ display: "flex" }}>
      {cab.nombre ? (
        <div className="vista_label vista_label_fuente">{cab.nombre}:</div>
      ) : (
        <></>
      )}
      <div className="filtroBuscador">
        <TextField
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          value={termino}
          style={{ width: "84%", padding: "0 0" }}
          type="text"
          // variant="outlined"
          label={props.label}
          className={classes.inputBComponent}
          onClick={cab.buscar_onClick === "s" ? () => showModal() : () => {}} // Abre modal on click o bien deja usar el input
          // onClick={() => showModal()}
        />
        <button
          onClick={() => showModal()}
          style={{
            padding: "0.23rem",
            backgroundColor: "#006F7A",
            color: "white",
            width: "15%",
            height: "100%",
          }}
          className="InputBusquedaLupa"
        >
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  );
};

export default withTools(InputBuscador);
