import React, {
  useContext,
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from "react";
import MaterialTable from "@material-table/core";
import ListadoContext from "../context/ListadoContext";
import PantallaContext from "../../context/PantallaContext";
import { agregarAcciones } from "../../helper/funciones";

import { Card, CardBody } from "reactstrap";
import HeaderConf from "../../components/HeaderConf";

import tableIcons from "./tableIcons";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import "./listado.scss";
import Filtros from "./Filtros";
import Botonera from "../Botonera";
import { fontTheme } from "../../../Pantalla/helper/themeMui";
import OrixiaRow from "./OrixiaRow";

import { TablePagination } from "@material-ui/core";
import SwitchColumnas from "./SwitchColumnas";
import MemoizedOrixiaRow from "./MemoizedOrixaRow";
import { bs } from "date-fns/locale";

const { typography: font } = fontTheme;

export const Listado = (props) => {
  const { data, loading, columnas } = props;

  const { opcionesListado, listadoBotones, Dispatch, cabeceras, datos } =
    useContext(ListadoContext);
  const { opciones_de_pantalla } = useContext(PantallaContext);

  const [selected, setSelected] = useState();

  const listadoBotonesInferiores = listadoBotones.filter(
    (b) => b.listado_boton_inferior === "s"
  );

  const theme = createTheme({
    typography: font,
    overrides: {
      MuiTableRow: {
        root: {
          "&:hover": {
            backgroundColor: "#006f7a40 !important",

            opacity: 0.9,
          },
          "&:nth-of-type(even)": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    },
  });

  const padding = "2px 6px";

  const calcPagesize = useCallback(() => {
    let size = 10;

    if (opcionesListado.cantdd_registros) {
      size =
        Number(opcionesListado.cantdd_registros) === 0
          ? data.length
          : Number(opcionesListado.cantdd_registros);
    }

    if (data.length === 0) {
      size = 0;
    }
    if (data.length < size) {
      size = data.length;
    }
    if (
      opcionesListado.cantdd_registros_max &&
      size > opcionesListado.cantdd_registros_max
    ) {
      size = opcionesListado.cantdd_registros_max;
    }
    return Number(size);
  }, [
    data.length,
    opcionesListado.cantdd_registros,
    opcionesListado.cantdd_registros_max,
  ]);

  const pageSize = calcPagesize();
  const tableRef = useRef(null);

  useEffect(() => {
    tableRef.current.dataManager.changePageSize(calcPagesize());
  }, [calcPagesize, columnas, data]);

  const styles = {
    gridColumn: opcionesListado.grid_span
      ? opcionesListado.grid_span
      : "1 / -1",
  };

  const mensajeSinDatos = (
    <p style={{ paddingTop: "15px" }}>
      {opcionesListado.consultaEjecutada === "n"
        ? ""
        : "No se encontraron datos"}
    </p>
  );

  agregarAcciones(opciones_de_pantalla, opcionesListado);

  function updateSelectedArray(selected, row) {
    row = row.filter((obj) => {
      const k = Object.keys(obj).filter((k) =>
        k.endsWith("listado_seleccion_habilitado")
      )[0];
      if (!k) return true;
      return obj[k] === 1;
    });
    
    if (!selected) {
      return row;
    }
    let newSelected = [...selected];

    const isTodosPresentes = row.every((itemToCheck) =>
      selected.some(
        (referenceItem) =>
          referenceItem.tableData.uuid === itemToCheck.tableData.uuid
      )
    );

    if (isTodosPresentes) {
      row.forEach((rowItem) => {
        newSelected = newSelected.filter(
          (ns) => ns.tableData.uuid !== rowItem.tableData.uuid
        );
      });

      return newSelected;
    }

    row.forEach((rowItem) => {
      const index = newSelected.findIndex(
        (selectedItem) => selectedItem.tableData.uuid === rowItem.tableData.uuid
      );

      if (index === -1) {
        newSelected.push(rowItem);
      } else {
        // newSelected.splice(index, 1);
      }
    });

    return newSelected;
  }

  return (
    <div
      style={styles}
      className={`animated fadeIn novedades_lista ${opcionesListado.className}`}
    >
      {/* <Row>
        <Col xs="12" sm="12"> */}
      <Card
        id={opcionesListado.id_a}
        style={{ border: "none", marginBottom: 0 }}
        className="listado_card"
      >
        <HeaderConf
          opciones={opcionesListado}
          className={"configuracion_pantalla_titulo_secundario"}
        />

        <CardBody style={{ padding: 0 }} className="listado_card_body">
          <ThemeProvider theme={theme}>
            <Filtros opciones={opcionesListado} />
            <MaterialTable
              tableRef={tableRef}
              isLoading={loading || opcionesListado === undefined}
              hideSortIcon={false}
              icons={tableIcons}
              localization={{
                header: {
                  //actions: "Acciones",
                },
                body: {
                  emptyDataSourceMessage: mensajeSinDatos,
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Filas",
                  labelRowsPerPage: "Productos x pág",
                  firstAriaLabel: "Primera",
                  lastAriaLabel: "Ultima",
                  firstTooltip: "Primera página",
                  lastTooltip: "Ultima página",
                  previousAriaLabel: "Página anterior",
                  previousTooltip: "Página anterior",
                  nextAriaLabel: "Próxima pagina",
                  nextTooltip: "Próxima pagina",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
              }}
              columns={useMemo(
                opcionesListado.consultaEjecutada === "s"
                  ? () =>
                      cabeceras
                        .sort((a, b) => a.orden - b.orden)
                        .filter((cab) => cab.componente !== "hidden")
                        .filter((cab) => cab.mostrar !== "n")
                        .map((cab, i) => {
                          //console.log("cab:  ", cab);

                          return {
                            title: cab.nombre_alias
                              ? datos[0][cab.nombre_alias]
                              : cab.nombre,
                            field: cab.campo_alias ?? cab.id_a,
                            width: undefined,
                            totalizar: cab.totalizar ?? undefined,
                            cellStyle: {
                              textAlign: cab.align ?? "center",
                              // width: cab.width ?? "10%",
                              padding: padding,
                              fontSize: "0.8em",
                              lineHeight: 1,
                              width: undefined,
                            },
                            id: cab.id_a,
                            headerStyle: {
                              textAlign: "left",
                              fontSize: "0.8rem",
                              padding: padding,
                              height: "0.8em",
                              width: undefined,
                            },
                            render: (data) => {
                              const totalStyle = {
                                ...cab,
                                borderTop: "double",
                              };

                              return (
                                <SwitchColumnas
                                  data={data}
                                  cab={data.TOTALES === 1 ? totalStyle : cab}
                                  indiceData={data.tableData.id}
                                  // Context={ListadoContext}
                                  id_elemento={
                                    cab.id_a + "_" + data.tableData.id
                                  }
                                />
                              );
                            },
                          };
                        })
                  : () => [],
                [cabeceras, datos, data]
              )}
              data={data}
              actions={[]}
              options={{
                filtering: false,
                actionsColumnIndex: -1,
                showTitle: false,
                toolbar:
                  opcionesListado.search === "s" && data.length !== 0 && data,
                pageSize: Number(pageSize),
                pageSizeOptions: [
                  pageSize,
                  5,
                  10,
                  20,
                  opcionesListado.cantdd_registros_max
                    ? opcionesListado.cantdd_registros_max
                    : 30,
                ],
                headerStyle: {
                  textAlign: "left",
                  fontWeight: "bold",
                  height: "57px",
                },
                search: opcionesListado.search === "s",
                searchFieldStyle: { top: "3px" },
                // tableLayout: "fixed",
                selection:
                  opcionesListado.listado_seleccion &&
                  opcionesListado.listado_seleccion === "s",
                showSelectAllCheckbox:
                  opcionesListado.showSelectAllCheckbox &&
                  opcionesListado.showSelectAllCheckbox === "s",
                showTextRowsSelected: opcionesListado.showTextRowsSelected,
                rowStyle: (r) => {
                  if (opcionesListado.rowStyle) {
                    return r.tableData.id === selected
                      ? {
                          background: "#bde0b1",
                          color: "black",
                          fontWeight: 600,
                        }
                      : {};
                  }
                  return {};
                },
              }}
              onSelectionChange={(row, l) => {
                if (!l && row.length !== 0) {
                  row = row.filter((r) => {
                    const c = tableRef.current.dataManager.pagedData.some(
                      (pd) => pd.tableData.uuid === r.tableData.uuid
                    );
                    return c;
                  });
                  row = updateSelectedArray(selected, row);
                }
                setSelected(row);

                Dispatch({
                  type: "SET_DATOS_SELECCIONADOS",
                  payload: {
                    row,
                    data: data.map((d, i) => {
                      d.tableData = { checked: false, index: i };
                      row.forEach((r) => {
                        if (r.tableData.index === i) {
                          d.tableData = r.tableData;
                        }
                      });

                      return d;
                    }),
                  },
                });

                // funcion select
                if (opcionesListado.listado_seleccion_habilitado) {
                  row = row.filter(
                    (r) =>
                      r[
                        opcionesListado.id_a + "_listado_seleccion_habilitado"
                      ] === 1
                  );
                }

                if (!!opcionesListado.onSelectionChange) {
                  opcionesListado.onSelectionChange(row);
                }
                return row;
              }}
              onRowClick={(e, row) => {
                if (opcionesListado.onRowClick) {
                  setSelected(row.tableData.id);
                  opcionesListado.onRowClick(e, row, data);
                }
              }}
              onRowDoubleClick={(e, row) => {
                if (opcionesListado.onRowDoubleClick) {
                  setSelected(row.tableData.id);
                  opcionesListado.onRowDoubleClick(e, row, data);
                }
              }}
              components={{
                Row: (props) => {
                  const habilitado =
                    opcionesListado.listado_seleccion_habilitado
                      ? props.data[
                          opcionesListado.id_a + `_listado_seleccion_habilitado`
                        ] === 1
                      : true;

                  return (
                    // <MTableBodyRow id={props.data._id} {...props} enableRowSelection={false}                    />
                    // <OrixiaRow {...props} enableRowSelection={habilitado} />
                    <MemoizedOrixiaRow
                      {...props}
                      enableRowSelection={habilitado}
                    />
                  );
                },
                Pagination: (props) => {
                  if (data.length === 0 || !data) return <></>;
                  return (
                    <>
                      <TablePagination
                        className={
                          opcionesListado.paginacion === "n" ||
                          Number(opcionesListado.cantdd_registros) === 0
                            ? "d-none"
                            : ""
                        }
                        {...props}
                      />
                    </>
                  );
                },
              }}
              // style={{ minWidth: "1300px", overflowX: "auto" }}
            />
            <Botonera
              handleSubmit={() => null}
              handleCancelar={() => null}
              loading={loading}
              buscar={0}
              cancelar={0}
              acciones={listadoBotonesInferiores}
              className={"container_buttons_inferiores"}
              context={ListadoContext}
              submit={opcionesListado.submit_button}
              submit_handleSubmit={opcionesListado.submit_button_handleSubmit}
              submit_texto={opcionesListado.submit_texto}
            />
          </ThemeProvider>
        </CardBody>
      </Card>
      {/* </Col>
      </Row> */}
    </div>
  );
};

export default Listado;
