import React from "react";
import Buscar from "./components/botonera/Buscar";
import "../Listado/botonera.scss";
import Cancelar from "./components/botonera/Cancelar";
import SwitchColumnas from "./components/SwitchColumnas";
import ListadoSubmitBoton from "./components/botonera/OrBoton.js";

const Botonera = ({
  handleSubmit,
  handleCancelar,
  loading,
  buscar,
  cancelar,
  acciones,
  className,
  context,
  submit,
  submit_handleSubmit,
  submit_texto,
}) => {
  if (!submit && !buscar && !cancelar && acciones?.length === 0) return <></>;
  return (
    <div className={`${className ?? "container_buttons_l"}`}>
      {buscar ? (
        <Buscar handleSubmit={handleSubmit} loading={loading} />
      ) : (
        <></>
      )}
      {cancelar ? <Cancelar handleCancelar={handleCancelar} /> : <></>}
      {submit ? (
        <ListadoSubmitBoton
          text={submit_texto ?? "Submit"}
          color="white"
          bg="#3065ac"
          handleSubmit={submit_handleSubmit}
          context={context}
        />
      ) : (
        <></>
      )}
      {loading
        ? []
        : acciones
            ?.sort((a, b) => a.orden - b.orden)
            .map((accion) => {
              return (
                <div
                  className="container_switch_acciones"
                  style={{
                    gridColumn:
                      accion.grid_span && accion.grid_span !== ""
                        ? accion.grid_span
                        : "span 2",
                  }}
                >
                  <SwitchColumnas
                    key={JSON.stringify(accion)}
                    cab={accion}
                    data={{}}
                    Context={context}
                  />
                </div>
              );
            })}
    </div>
  );
};

export default Botonera;
