import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import Label from "./LabelF";
import "react-datepicker/dist/react-datepicker.css";
//import "../filtros.scss";
import ar from "date-fns/locale/es";

import { TextField } from "@mui/material";
registerLocale("ar", ar);

function parseISOString(s) {
  if (!s) return;
  var b = s.split(/\D+/).filter((b) => b);

  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

function transformarFechaISOaMySQL(fechaISO) {
  if (
    fechaISO === null ||
    fechaISO === undefined ||
    fechaISO === "" ||
    isNaN(Date.parse(fechaISO))
  )
    return null;

  const fecha = new Date(fechaISO);
  const fechaMySQL = fecha.toISOString().replace("T", " ").replace("Z", "");
  return fechaMySQL;
}

const FechaSimple = (props) => {
  const { id_a, setValor, valor, grid_span, error, setError, cab, data } =
    props;

  const def = data[cab.id_a + "_default"] ?? cab.default;

  if (!valor && def) {
    setValor(def);
  }

  const [fecha, setFecha] = useState("");

  const styles = {
    gridColumn: grid_span,
  };

  useEffect(() => {
    setFecha(parseISOString(valor));
    if (!valor) {
      setFecha(parseISOString(def));
    }
  }, [def, valor]);

  return (
    <div style={styles}>
      <div
        className="filtro_grid_fecha"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Label
          label={cab.nombre}
          opcionales_null={cab.opcionales_null}
          permite_null={cab.permite_null}
        />

        <DatePicker
          timeFormat="p"
          timeIntervals={15}
          dateFormat={cab.fecha_formato}
          locale="ar"
          selected={fecha ?? null}
          showYearDropdown={true}
          placeholderText={cab.placeholder ?? cab.fecha_formato}
          onChange={(date) => {
            setError((e) => {
              return { ...e, [id_a]: false };
            });

            setFecha(date);
            setValor(transformarFechaISOaMySQL(date));
          }}
          customInput={
            <TextField
              className={error[id_a] ? "filtro_fecha_input_error" : " w-100"}
              label={
                cab.label && cab.label.trim() !== "" ? cab.label : undefined
              }
            />
          }
          isClearable
          // withPortal
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export default FechaSimple;
