import React, { useEffect, useState } from "react";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import { convertirANumero } from "../../../helper/funciones";
import Label from "./LabelF";

export const SelectInput = ({
  setValor,
  valor,
  cab,
  error,
  setError,
  data,
}) => {
  const {
    id_a,
    label,
    grid_span,
    opcionales_null,
    permite_null,
    margin_bottom_abm,
    width,
    opciones,
  } = cab;

  const styles = {
    gridColumn: grid_span,
  };

  const style_input_abm = {
    width: width,
    marginBottom: margin_bottom_abm,
  };

  useEffect(() => {
    if (cab.default && !valor) {
      setValor(cab.default);
    }
  }, [cab.default, setValor]);

  return (
    <div style={style_input_abm}>
      <FormControl fullWidth>
        <Label
          label={label}
          opcionales_null={opcionales_null}
          permite_null={permite_null}
        />
        <Select
          style={{ width: "min-content" }}
          defaultValue={cab.default}
          variant="outlined"
          size="small"
          onChange={(e) => {
            setValor(e.target.value);
            setError((e) => {
              const ne = { ...e };
              ne[id_a] = false;

              return ne;
            });
          }}
          value={convertirANumero(valor)}
          error={error[id_a]}
        >
          {opciones &&
            opciones.map((opcion) => (
              <MenuItem
                style={{ minWidth: "200px", textAlign: "center" }}
                value={convertirANumero(opcion.value)}
                key={opcion.value + opcion.label}
              >
                {opcion.default ? <em>{opcion.label}</em> : opcion.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectInput;
